###
#
# @file application
#
###

class Application

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      input:              'input'

    @app.register_turbolinks_load_handler @init

    $(document).on 'click', '[data-application-notes]', @toggle_doctor_notes

    $(document).on 'click', '[data-application-next-step]', @application_next_step
    $(document).on 'click', '[data-application-prev-step]', @application_prev_step

    $(document).on 'click', '[data-application-save-exit]', @save_and_exit

    $(document).on 'change', 'input[name="application[metadata][type]"]', @application_type_changed

  init: =>
    if $(@el.body).is('.applications, .dispensaries')
      @form_fields_visible_handler()
    else
      return
    


  application_type_changed: (e)=>
    input_foto_id = $("#foto_id_cannabis")
    dropzone_foto_id = input_foto_id.parent().find('.dropzone-area')

    if e.target.value.includes("Renovación")
      dropzone_foto_id.addClass 'required'
    else 
      dropzone_foto_id.removeClass 'required'


  form_fields_visible_handler: =>
    $(document).on 'change', @el.input, =>
      $('[data-field-visible="false"]').each (index, elem)->
        selector_target = $(elem).attr 'data-field-selector'
        selector_val = $(elem).attr 'data-field-value'
        $selector_dom = $("[name='application[metadata][#{selector_target}]']:checked")

        if $selector_dom.val() == selector_val
          $(elem).slideDown()

          # Enable required for date field
          $(elem).find("[data-validator='greater_than_21_years']").prop('required', true)

          $(elem).find('[data-dropzone]').addClass 'required'
          $(elem).find('[data-dropzone]').parent('.form-field').find('[data-file-blob-id]').attr('data-blob-required', true)
        else
          $(elem).hide()

          # Disable required for date field
          $(elem).find("[data-validator='greater_than_21_years']").prop('required', false)

          $(elem).find('[data-dropzone]').removeClass 'required'
          $(elem).find('[data-dropzone]').parent('.form-field').find('[data-file-blob-id]').attr('data-blob-required', false)


  toggle_doctor_notes: (e)=>
    e.preventDefault()
    $(e.currentTarget).toggleClass 'expanded'
    $(e.currentTarget).parents('.results-table-item').find('.item-doctor-notes').slideToggle()




  application_next_step: (e)=>
    e.preventDefault()
    current_step = $(e.currentTarget).data 'application-next-step'
    next_step = $(e.currentTarget).data 'application-next-step'
    fieldset = $(e.currentTarget).parents('.fieldset')
    current_fieldset = $("[data-fieldset-step=#{current_step}]")

    if @validate_fieldset(fieldset)
      $("[data-fieldset-step=#{current_step - 1}]").hide()
      $("[data-fieldset-step=#{next_step}]").show()
      @scroll_to_top()
      @set_sidebar_current_step next_step

      if current_fieldset.find('.dropzone-area.required').length > 0 && current_fieldset.find('.dropzone-area.required').is(":visible")
        current_fieldset.find('.button--next').addClass 'disabled'



  application_prev_step: (e)=>
    e.preventDefault()

    current_step = $(e.currentTarget).data 'application-prev-step'
    prev_step = $(e.currentTarget).data 'application-prev-step'

    $("[data-fieldset-step=#{current_step + 1}]").hide()
    $("[data-fieldset-step=#{prev_step}]").show()

    @scroll_to_top()
    @set_sidebar_current_step prev_step

    $(".button--next").removeClass 'disabled'


  set_sidebar_current_step: (current)=>
    $(".submit-sidebar li").removeClass 'current'
    $(".submit-sidebar li:nth-child(#{current})").addClass 'completed'
    $(".submit-sidebar li:nth-child(#{current+1})").addClass 'current'



  scroll_to_top: =>
    target = $("#application_top")
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 1000);


  validate_fieldset: (fieldset)=>
    $fieldset = fieldset

    # Validate each input inside the fieldset
    $fieldset.find('input, select, textarea').each (i, el)=>
      # console.log "#{i} - #{el.id}"
      if el.id
        $(".application-form").foundation 'validateInput', $("##{el.id}")

    # Check if the fieldset contains invalid fields before proceed
    if $fieldset.find('.is-invalid-label').length > 0
      false
    else
      true


  save_and_exit: (e)=>
    e.preventDefault()
    $('.application-form').find('input, select, textarea').removeAttr 'required'
    $('[name="application[action]"]').val "draft"
    $('.application-form').submit()


module.exports = Application
