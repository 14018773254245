###
#
# @file header
#
###

class Header

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      header:             '[data-header]'
      mobile_menu:        '[data-mobile-menu]'
      header_menu_toggle: '[data-header-menu-toggle]'
      flash_container:    '.flash-notification'
      flash_close_button: '[data-close-flash]'

    ##
    # DOM Element Modifiers
    # @type {string}
    ##
    @el_modifiers =
      body:
        header_is_hidden: 'body--header-is-hidden'
        lock_scroll:      'body--lock-scroll'
      header:
        hidden: 'header--hidden'
      header_menu:
        open: 'header__menu--open'
      header_menu_toggle:
        close: 'header__menu-toggle--close'
      flash:
        close: 'animate-close'

    @app.register_turbolinks_load_handler @init

  init: =>
    unless $(@el.header).length
      return
    @header_menu()
    @flash_notice()

  flash_notice: =>
    $(document).on 'click', @el.flash_close_button, =>
      $(@el.flash_container).addClass @el_modifiers.flash.close

  header_menu: =>
    $(document).off 'click', @el.header_menu_toggle
    $(document).on 'click', @el.header_menu_toggle, =>
      $(@el.mobile_menu).toggleClass 'expanded'
      $(@el.header_menu_toggle).toggleClass @el_modifiers.header_menu_toggle.close


module.exports = Header
