###
#
# @file forms
#
###

class Forms

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      input:              'input'

    @app.register_turbolinks_load_handler @init

    $(document).on 'invalid.zf.abide', @invalid_field
    $(document).on 'valid.zf.abide', @valid_field
    # $(document).on 'valid', @enable_button

  init: =>
    @iterate_fields()

  enable_button: (ev,frm)=>
    alert "form valido!"
    #$(frm).find('[data-disabled]').removeClass 'disabled'

  invalid_field: (index, field)=>
    $input = field
    $container = $input.parents('.form-field')
    $container.removeClass 'form-field--is-valid'
    $container.addClass 'form-field--has-error'


  valid_field: (index, field)=>
    $input = field
    $container = $input.parents('.form-field')
    $container.removeClass 'form-field--has-error'
    $container.addClass 'form-field--is-valid'


  setActive: (el, active) =>
    formField = el.parentNode.parentNode
    if active
      formField.classList.add('form-field--is-active')
    else
      formField.classList.remove('form-field--is-active')
      if el.value == '' then formField.classList.remove('form-field--is-filled') else formField.classList.add('form-field--is-filled')


  iterate_fields: =>
    $('.form-field__input, .form-field__textarea, .form-field__select').each (index, el)=>
      el.onblur = ()=>
        @setActive(el, false)
      el.onfocus = ()=>
        @setActive(el, true)

module.exports = Forms
