###
#
# @file payment-athmovil
#
###

class PaymentATHMovil

  constructor: (@app, config) ->
    @app.register_turbolinks_load_handler @init

  init: =>
    unless $("body.page-payment").length
      return

  payment_completed: (response)=>
    app_id = response.metadata1.replace('MEDCA-','')
    _UD.components.Payment.push_payment_data({
      referenceNumber: "#{response.referenceNumber}",
      total: "#{response.total}",
      applicationId: "#{app_id}",
      gateway: "ATHMovil"
    })

  payment_cancelled: (response)=>
    alert "El pago ha sido cancelado"

  payment_expired: (response)=>
    alert "El tiempo disponible para el pago ha expirado. Recargue la página e intente nuevamente."

module.exports = PaymentATHMovil
