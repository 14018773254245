###
#
# @file user_dashboard.coffee
# @desc This controller manages and registers page components and delegates
# turbolinks events to them.
#
# It's also a one-stop shop for passing configurations to submodules (things like
# class names that might change, etc)
#
###


###
#
# Set up Foundation dependencies
#
###

# import WhatInput from 'what-input'
window.$ = require 'jquery'
import { Foundation } from 'foundation-sites/js/foundation.core.js'
import Turbolinks from 'turbolinks';

# Foundation Utils
import { Box } from 'foundation-sites/js/foundation.util.box.js'
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader.js'
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard.js'
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery.js'
import { Triggers } from 'foundation-sites/js/foundation.util.triggers.js'
import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll.js'
import { Nest } from 'foundation-sites/js/foundation.util.nest.js'
import { Touch } from 'foundation-sites/js/foundation.util.touch.js'
import { Motion } from 'foundation-sites/js/foundation.util.motion.js'

# Foundation Plugins core
import { Abide } from 'foundation-sites/js/foundation.abide.js'
import { Accordion } from 'foundation-sites/js/foundation.accordion.js'
import { Dropdown } from 'foundation-sites/js/foundation.dropdown.js'
# import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu.js'
import { Equalizer } from 'foundation-sites/js/foundation.equalizer.js'
# import { Interchange } from 'foundation-sites/js/foundation.interchange.js'
# import { Magellan } from 'foundation-sites/js/foundation.magellan.js'
import { Reveal } from 'foundation-sites/js/foundation.reveal.js'
# import { Tooltip } from 'foundation-sites/js/foundation.tooltip.js'
import { Tabs } from 'foundation-sites/js/foundation.tabs.js'

# import { DirectUpload } from "@rails/activestorage"
import MyUploader from "modules/shared/uploader.js"
import Dropzone from "dropzone"
import IMask from 'imask'

Dropzone.autoDiscover = false
moment = require('moment')

# This object is an argument to our controller, below.
foundation_plugins =
  Abide: Abide
  Accordion: Accordion
  Dropdown: Dropdown
  # 'dropdown-menu': DropdownMenu
  Equalizer: Equalizer
  # Interchange: Interchange
  Tabs: Tabs
  Reveal: Reveal
  # Tooltip: Tooltip


Foundation.plugin MediaQuery, 'MediaQuery'
Foundation.plugin Triggers, 'Triggers'
Foundation.plugin onImagesLoaded, 'onImagesLoaded'
Foundation.plugin Keyboard, 'Keyboard'
Foundation.plugin SmoothScroll, 'SmoothScroll'
Foundation.plugin Motion, 'Motion'
Foundation.plugin Nest, 'Nest'
Foundation.plugin Touch, 'Touch'
Foundation.plugin Abide, 'Abide'
Foundation.plugin Equalizer, 'Equalizer'
# Foundation.plugin Interchange, 'Interchange'
Foundation.plugin Accordion, 'Accordion'
Foundation.plugin Dropdown, 'Dropdown'
# Foundation.plugin DropdownMenu, 'DropdownMenu'
# Foundation.plugin Magellan, 'Magellan'
Foundation.plugin Reveal, 'Reveal'
# Foundation.plugin Tooltip, 'Tooltip'
Foundation.plugin Tabs, 'Tabs'


greater_than_21_years = ($el, required, parent) ->
  value = $el.val().split('.')

  if required
    if value.length == 3
      if value[2].length < 4
        return false
      else
        age = Math.floor((new Date() - new Date("#{value[1]}/#{value[0]}/#{value[2]}").getTime()) / 3.15576e+10)
        if age >= 21 then return true else return false
    else
      return false
  else
    return true


valid_date = ($el, required, parent) ->
  value = $el.val().split('/')

  if value.length == 3
    value_length = value[0].length < 2 || value[1].length < 2 || value[2].length < 4
    value_val = value[0] == '00' || value[1] == '00' || value[2] == '0000'

    if value_length || value_val
      return false
    else
      return true
  else
    return false


# Foundation.Abide.defaults.validators['user_password'] = userPassword
Foundation.Abide.defaults.patterns['alpha'] = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/
Foundation.Abide.defaults.patterns['alpha_numeric'] = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*)$/
Foundation.Abide.defaults.patterns['pass'] = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z#?!@$%^&*-\d]{8,}$/
Foundation.Abide.defaults.patterns['ssn'] = /^\d{3}-?\d{2}-?\d{4}$/
Foundation.Abide.defaults.patterns['phone'] = /^\(\d{3}\) \d{3}-\d{4}$/
Foundation.Abide.defaults.validators['greater_than_21_years'] = greater_than_21_years;
Foundation.Abide.defaults.validators['valid_date'] = valid_date;

# register foundation with JQuery
Foundation.addToJquery($)

###
#
# End Foundation Setup
#
###




# Here's the master list of our bespoke components and their config settings.
components =
  Header:
    component: require 'modules/user/header.coffee'
    config: {}
  Application:
    component: require 'modules/user/application.coffee'
    config: {}
  ApplicationDoctor:
    component: require 'modules/doctor/application.coffee'
    config: {}
  Form:
    component: require 'modules/shared/form.coffee'
    config: {}
  Signature:
    component: require 'modules/doctor/signature.coffee'
    config: {}
  Profile:
    component: require 'modules/user/profile.coffee'
    config: {}
  Payment:
    component: require 'modules/user/payment.coffee'
    config: {}
  PaymentATHMovil:
    component: require 'modules/user/payment-athmovil.coffee'
    config: {}
  Notifications:
    component: require 'modules/shared/notifications.coffee'
    config: {}


class UserDashboard
  constructor: (components, @foundation_plugins) ->

    # An array of functions to call on turbolinks:load
    @turbolinks_load_handlers = []


    # Save references to instantiated components and pass them a
    # reference to this controller
    @components = {}
    for n, c of components
      @components[n] = new c.component(this, c.config)

    $(document).ready @onready # just run once
    $(document).on 'turbolinks:render', @handle_turbolinks_load # hook into the render event after first page load
    $(document).on 'turbolinks:before-render', @handle_turbolinks_before_render
    $(document).on 'turbolinks:click', @handle_turbolinks_click
    #$(document).on 'keyup', '.textcounter', @textarea_counter
    $(document).on 'click', '[data-toggle-text]', @toggle_text
    $(document).on 'keyup', '.live-password-validation', @live_password_validation
    $(document).on 'submit', '.form-send-to-dispensary', @submitFormDispensary


  handle_turbolinks_before_render: (e)=>
    # console.log('turbolinks:before-render');
    # Removes the FOUC-prevention
    e.originalEvent.data.newBody.style.removeProperty('opacity')

  handle_turbolinks_click: (e)=>
    return false if $(e.target).is("[data-open]") # turbolinks messes up foundation's reveal if we don't do this
    true

  handle_turbolinks_load: =>
    #console.log 'turbolinks:load'
    @update_foundation()
    @page_loader() if $('body.doctors-applications').length != 1
    f.call() for f in @turbolinks_load_handlers


  onready: =>

    # this stuff only runs once...
    # Ensure AJAX requests go out with the CSRF token
    if $('body.page-payment').length == 0
      $.ajaxSetup
        beforeSend: (xhr)->
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))

    @handle_turbolinks_load()
    @page_loader() if $('body.doctors-applications').length != 1

  page_loader: ()=>
    setTimeout (->
      $('#loader').fadeOut()
      return
    ), 500

  register_turbolinks_load_handler: (func)->
    @turbolinks_load_handlers.push func


  live_password_validation: (e)=>
    password = $(e.currentTarget).val()

    $('.password-criteria').show()

    # Lowercase
    if password.match(/[a-z]+/)
      $('.password-criteria li:nth-child(1)').addClass 'checked'
    else
      $('.password-criteria li:nth-child(1)').removeClass 'checked'

    # Uppercase
    if password.match(/[A-Z]+/)
      $('.password-criteria li:nth-child(2)').addClass 'checked'
    else
      $('.password-criteria li:nth-child(2)').removeClass 'checked'

    # Number
    if password.match(/[0-9]+/)
      $('.password-criteria li:nth-child(3)').addClass 'checked'
    else
      $('.password-criteria li:nth-child(3)').removeClass 'checked'

    # Symbol
    if password.match(/[#?!@$%^&*-]+/)
      $('.password-criteria li:nth-child(4)').addClass 'checked'
    else
      $('.password-criteria li:nth-child(4)').removeClass 'checked'


    # Length
    if password.length >= 8
      $('.password-criteria li:nth-child(5)').addClass 'checked'
    else
      $('.password-criteria li:nth-child(5)').removeClass 'checked'



  textarea_counter: (e)=>
    $text_area = $(e.currentTarget)
    count = $text_area.val().length
    limit = $text_area.attr 'minlength'
    if limit - count <= 0
      t = $text_area
      t.val t.val().substr(0, limit)
    else
      $text_area.next('.counter').text limit - count


  toggle_text: (e)=>
    text = $(e.currentTarget).data 'toggle-text'
    $(e.currentTarget).find('span').text text


  submitFormDispensary: (e)=>
    form = $(e.currentTarget)
    button = form.find('.btn-submit-dispensary')
    button.val('Enviando... espera un momento.')
    button.addClass 'disabled'


  
  load_data_mask: ()=>
    if $('[data-mask]').length > 0
      $('[data-mask]').each ->
        input = document.getElementById $(this).attr('id') 
        type = $(this).attr('data-mask')
        switch type
          when 'date'
            momentFormat = 'MM-DD-YYYY'
            maskOptions =
              mask: Date
              pattern: momentFormat
              lazy: false 
              format: (date) ->
                moment(date).format(momentFormat)
              parse: (str) -> 
                moment(str, momentFormat)
              blocks:
                MM:
                  mask: IMask.MaskedRange
                  from: 1
                  to: 12
                DD:
                  mask: IMask.MaskedRange
                  from: 1
                  to: 31
                YYYY:
                  mask: IMask.MaskedRange
                  from: 1950
                  to: 2050
            IMask(input,maskOptions)
          when 'phone'
            maskOptions = {
              mask: "(000) 000-0000",
            }
            IMask(input,maskOptions)
          when "social_security_number"
            maskOptions = {
              mask: "000-00-0000",
            }
            IMask(input,maskOptions)


  load_drop_zone: ()=>
    if $("[data-dropzone]").length > 0

      # Add a Dropzone instance for element
      $("[data-dropzone]").each ->
        @meta_field = $(this).parents('.form-field').find('input[type="hidden"]')
        @dropzone_dom = $(this)
        @dropzone_was_required = @dropzone_dom.hasClass('required')
        @dropzone_dom_fieldset = @dropzone_dom.parents('.fieldset')

        myDropzone = new Dropzone(this, {
          url: $("meta[name=direct-upload-url]").attr('content')
          acceptedFiles: ".jpg,.png,.gif,.pdf"
          maxFiles: 8
          parallelUploads: 1,
          uploadMultiple: true
          autoProcessQueue: false
          addRemoveLinks: true
          createImageThumbnails: true
          thumbnailWidth: 180
          thumbnailHeight: 180
          thumbnailMethod: 'contain'
          dictInvalidFileType: "El formato del archivo no es válido"
          dictRemoveFile: "Eliminar"
          dictMaxFilesExceeded: "No puedes subir más fotos"
          params: {
            authenticity_token: $("meta[name=csrf-token]").attr('content')
          }
        })

        myDropzone.on "addedfile", (file)=>
          url = $("meta[name=direct-upload-url]").attr('content')

          if file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png" || file.type == "application/pdf"

            @dropzone_dom.removeClass 'dropzone-area--has-error'
            @dropzone_dom.removeClass 'required'
            my_uploader = new MyUploader(file, url, @dropzone_dom)

            if @dropzone_dom_fieldset.find('.dropzone-area.required').length == 0
              @dropzone_dom_fieldset.find('.button--next').removeClass 'disabled'

          else
            @dropzone_dom.addClass("dropzone-area--has-error")
            myDropzone.removeFile(file)

        myDropzone.on "removedfile", (file)=>
          signed_id = @dropzone_dom.parents('.form-field').find('[data-file-blob-id]').data('file-blob-id')
          @dropzone_dom.parents('.form-field').find('[data-file-blob-id]').val("")
          @dropzone_dom.parents('.form-field').find('[data-file-blob-id]').attr('data-file-blob-id', '')
          $("input[value='#{signed_id}']").remove()

          if myDropzone.files.length == 0
            @dropzone_dom.addClass 'required' if @dropzone_was_required
            @dropzone_dom.removeClass 'preview-mode'
            @dropzone_dom.find('.progress-load').css('width', '0%')
            @dropzone_dom.find('.status')
            .removeClass("uploading")
            .html("Arrastrar y colocar aquí o <span>buscar</span>")

            if @dropzone_dom_fieldset.find('.dropzone-area.required').length > 0
              @dropzone_dom_fieldset.find('.button--next').addClass 'disabled'


  update_foundation: (destroy = false)=>
    if destroy
      console.log "Destroying foundation plugins"
      for name, plugin of @foundation_plugins
        try
          $("[data-#{name.toLowerCase()}]").each ->
            $(this).foundation('_destroy')
        catch error
    else
      console.log "Initializing foundation plugins"
      for name, plugin of @foundation_plugins
        $("[data-#{name.toLowerCase()}]").each ->
          new plugin $(this)
      $.triggersInitialized = false
      Foundation.Triggers.init($) #setting triggersInitialized to false ensures that MutationObservers will be set on new DOM elements
      @load_data_mask() unless $('body.doctors-applications').length
      @load_drop_zone()
      

window._UD = new UserDashboard(components, foundation_plugins)