###
#
# @file payment
#
###

class Payment

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      input:              'input'

    @app.register_turbolinks_load_handler @init

  init: =>
    unless $(@el.body).is('.applications, .dispensaries')
      return


  push_payment_data: (payload)=>
    token = $('meta[name=csrf-token]').attr('content')
    new Promise (resolve, reject)=>
      payload = JSON.parse JSON.stringify payload
      $.ajax
        url: "/applications/#{payload.applicationId}/payment/process"
        type: "POST"
        headers:
          'X-CSRF-Token': token #Add the token manually
        data: payload
        dataType: 'json'
        success: (payload)=>
          window.location.href = payload.redirect_to
          resolve()
        error: @push_failure.bind this, reject


  push_failure: (reject, jqXHR)=>
    console.log jqXHR
    reject('There was an unexpected error')


module.exports = Payment
