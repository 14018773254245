###
#
# @file application
#
###

Lightbox = require('lightbox2/dist/js/lightbox.js')

class ApplicationDoctor

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:                   'body'
      application_form:       '.application-doctor-review'
      action_button:          '[data-application-action]'
      action_input:           '[name="application[action]"]'

    @app.register_turbolinks_load_handler @init

    $(document).on 'click', @el.action_button, @application_action
    $(document).on 'change', '#doctor_notes', @doctor_notes_clear
    
  init: =>
    unless $(@el.body).hasClass 'doctors-applications'
      return

    @notes_container = $("#doctor_notes_container")
    @notes_error = @notes_container.find('.form-error')

    Lightbox.init()


  application_action: (e)=>
    e.preventDefault()
    action = $(e.currentTarget).data 'application-action'
    $(@el.action_input).val action

    if action == "denied" || action == "errors"
      if $("#doctor_notes").val() == ""
        @notes_container.addClass 'form-field--has-error'
        @notes_error.show()
        return false
      else
        $(@el.application_form).submit()
    else
      $(@el.application_form).submit()


  doctor_notes_clear: (e)=>
    @notes_container.removeClass 'form-field--has-error'
    @notes_error.hide()



module.exports = ApplicationDoctor
